import React, {Component} from 'react';
import {ACCESS_TOKEN} from "../../constants";
import Alert from "react-s-alert";

class Logout extends Component {

    constructor(props) {
        super(props);
        console.log(props);
    }

    componentDidMount() {
        localStorage.clear();
        window.localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ACCESS_TOKEN);
        this.setState({
            authenticated: false,
            currentUser: null
        });
        Alert.success("You're safely logged out!");
    }

    render() {

        return (
            <div className="profile-container">
                <div className="container">
                    <div className="profile-info">
                        <div className="profile-name">
                            <h2>{this.props.currentUser.name}</h2>
                            <p className="profile-email">{this.props.currentUser.email}</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Logout
