 export const API_BASE_URL = 'https://auth.3ton.eu';
 export const KABINET_BASE_URL = 'https://kabinet.3ton.eu';
//export const API_BASE_URL = 'http://localhost:9001';
//export const KABINET_BASE_URL = 'http://localhost:8081';
export const ACCESS_TOKEN = 'accessToken';
export const OAUTH2_REDIRECT_URI = 'https://login.3ton.eu/oauth2/redirect'
//export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'
export const GOOGLE_AUTH_URL = API_BASE_URL + '/auth/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/auth/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/auth/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'
// export const ACCESS_TOKEN = 'accessToken';
//
//
// export const GOOGLE_AUTH_URL = API_BASE_URL + '/auth/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
// export const FACEBOOK_AUTH_URL = API_BASE_URL + '/auth/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
// export const GITHUB_AUTH_URL = API_BASE_URL + '/auth/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
