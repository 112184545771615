import React, {Component} from 'react';
import './Profile.css';
import {ACCESS_TOKEN, KABINET_BASE_URL} from "../../constants";

class Profile extends Component {
    constructor(props) {
        super(props);
        console.log(props);
    }

    componentDidMount() {
        const token = localStorage.getItem(ACCESS_TOKEN);
        setTimeout(() => {
            window.location.href = KABINET_BASE_URL + '/login?' + ACCESS_TOKEN + '=' + token;
        }, 1000);
    }

    render() {
        return (
            <div className="profile-container">
                <div className="container">
                    <div className="profile-info">
                        <div className="profile-avatar">
                            {
                                this.props.currentUser.imageUrl ? (
                                    <img src={this.props.currentUser.imageUrl} alt={this.props.currentUser.name}/>
                                ) : (
                                    <div className="text-avatar">
                                        <span>{this.props.currentUser.name && this.props.currentUser.name[0]}</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="profile-name">
                            <h2>{this.props.currentUser.name}</h2>
                            <p className="profile-email">{this.props.currentUser.email}</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Profile
